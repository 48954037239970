import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'

Vue.use(VueRouter)

const routes = [
  //首页
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  //植发技术
  {
    path: '/Technology',
    name: 'Technology',

    component: () => import('../views/Technology/Technology.vue')
  },
  //大事件
  {
    path: '/Bigevent',
    name: 'Bigevent',

    component: () => import('../views/Bigevent/Bigevent.vue')
  },
  //全国院部
  {
    path: '/Department',
    name: 'Department',

    component: () => import('../views/Department/Department.vue')
  },
  //植发专家
  {
    path: '/Expert',
    name: 'Expert',

    component: () => import('../views/Expert/Expert.vue')
  },
  //公司荣誉
  {
    path: '/Honor',
    name: 'Honor',

    component: () => import('../views/Honor/Honor.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
