<template>
  <div class="app-foot">
    {{footerMsgCopyright}}
    <span v-html="footerMsgName">{{footerMsgName}}</span>
  </div>
</template>

<script>
export default {
  name: 'AppFoot',
  data() {
	if (location.host.includes('zhifaxiaoguo.com')){
		return{
			// 版权说明的文字
			footerMsgCopyright: 'Copyright © 2016 zhifaxiaoguo.com Inc.All Rights Reserved.',
			// 单位
			footerMsgName: '北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备12001479号-9</a>'
		}
	}else if(location.host.includes('shanghaizhifa.net')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 shanghaizhifa.net Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '上海碧莲盛医疗美容门诊部有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">沪ICP备19019644号-1</a>'
		}
	}else if(location.host.includes('nanchangshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 nanchangshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '南昌碧莲盛医疗美容门诊部有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">赣ICP备19004303号-3</a>  <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=36010302000525" style="display:inline-block;text-decoration:none;color: #fff; text-decoration:none;"><img src="./img/gabeian.png" style="float:left;"/>京公网安备36010302000525号</a>'
		}
	}else if(location.host.includes('shenzhenyangfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 shenzhenyangfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '深圳碧莲盛医疗美容门诊部 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">粤ICP备2020124078号-1</a>'
		}
	}else if(location.host.includes('guangzhoushengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 guangzhoushengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '广州碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">粤ICP备20006855号-1</a>'
		}
	}else if(location.host.includes('ningbozhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 ningbozhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '宁波江北白沙盛莲医疗美容诊所有限公司 <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020502000771" style="color:#fff;"><img src="/skin/zhifajiage2015/images/gongan.png">浙公网安备 33020502000771号</a> <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">浙ICP备2021001948号-2</a>'
		}
	}else if(location.host.includes('guiyangzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 guiyangzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '贵阳盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">黔ICP备20000671号-1</a>'
		}
	}else if(location.host.includes('xiningzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 xiningzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '西宁盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">青ICP备2021000374号-1</a>'
		}
	}else if(location.host.includes('zhengzhoushengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 zhengzhoushengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '郑州碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">豫ICP备2020034726号-6</a>'
		}
	}else if(location.host.includes('taiyuanyangfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 taiyuanyangfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '太原碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">晋ICP备2021002398号-2</a>'
		}
	}else if(location.host.includes('taiyuanshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 taiyuanshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '太原碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">晋ICP备2021002398号-3</a>'
		}
	}else if(location.host.includes('lanzhouzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 lanzhouzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '兰州盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">陇ICP备2020003580号-1</a>'
		}
	}else if(location.host.includes('lanzhoushengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 lanzhoushengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '兰州盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">陇ICP备2020003580号-2</a>'
		}
	}else if(location.host.includes('shenzhenshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 shenzhenshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '深圳碧莲盛医疗美容门诊部 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">粤ICP备2020124078号-1</a>'
		}
	}else if(location.host.includes('shenzhenyangfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 shenzhenyangfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '深圳碧莲盛医疗美容门诊部 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">粤ICP备2020124078号-2</a>'
		}
	}else if(location.host.includes('xianzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 xianzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '西安碑林碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">陕ICP备2021004040号-1</a>'
		}
	}else if(location.host.includes('qingdaoshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 qingdaoshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '青岛盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">鲁ICP备2020046531号-1</a>'
		}
	}else if(location.host.includes('qingdaoyangfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 qingdaoyangfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '青岛盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">鲁ICP备2020046531号-2</a>'
		}
	}else if(location.host.includes('hefeishengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 hefeishengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '合肥碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">皖ICP备2020019904号-2</a>'
		}
	}else if(location.host.includes('hefeizhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 hefeizhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '合肥碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">皖ICP备2020019904号-1</a>'
		}
	}else if(location.host.includes('jinanzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 jinanzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '济南步步莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">鲁ICP备20003657号-2</a>'
		}
	}else if(location.host.includes('jinanshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 jinanshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '济南步步莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">鲁ICP备20003657号-1</a>'
		}
	}else if(location.host.includes('kunmingshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 kunmingshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '昆明盛莲医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">滇ICP备20001031号-1</a>'
		}
	}else if(location.host.includes('shenyangshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 shenyangshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '沈阳和平碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">辽ICP备20001441号-1</a>'
		}
	}else if(location.host.includes('dalianshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 dalianshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '大连盛之美医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">辽ICP备20001323号-1</a>'
		}
	}else if(location.host.includes('chongqingshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 chongqingshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '重庆碧莲盛医疗美容门诊有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">渝ICP备20001042号-1</a>'
		}
	}else if(location.host.includes('wuhanshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 wuhanshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '武汉碧莲盛医疗美容门诊部有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">鄂ICP备20001769号-1</a>'
		}
	}else if(location.host.includes('changshazhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 changshazhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '长沙市开福区碧莲盛医疗美容门诊有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">湘ICP备20002609号-1</a>'
		}
	}else if(location.host.includes('chengdushengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 chengdushengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '成都青羊碧莲盛医疗美容诊所有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">蜀ICP备20002740号-1</a>'
		}
	}else if(location.host.includes('nanningshengfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 nanningshengfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '南宁碧莲盛医疗美容有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">桂ICP备20000490号-1</a>'
		}
	}else if(location.host.includes('sjzzhifa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 sjzzhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '石家庄盛莲医疗美容服务有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">冀ICP备20001750号-1</a>'
		}
	}else if(location.host.includes('biliansheng.net')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 biliansheng.net Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '北京碧莲盛护发有限公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备2022005019号-2</a>'
		}
	}else if(location.host.includes('zhuanyezhongfa.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 zhuanyezhongfa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备12001479号-49</a>'
		}
	}else if(location.host.includes('zhifaxiaoguo.com')){
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 zhifaxiaoguo.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备12001479号-9</a>'
		}
	}else {
		return {
		  // 版权说明的文字
		  footerMsgCopyright: 'Copyright © 2016 blszhifa.com Inc.All Rights Reserved.',
		  // 单位
		  footerMsgName: '北京碧莲盛不剃发植发医疗美容门诊部有限责任公司 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #fff; text-decoration:none;">京ICP备12001479号-5</a>'
		}
	}

  }
}
</script>

<style scoped>
.app-foot {
  /* footer 固定在页面底部 */
  min-height: 35px;
  background-color: #505c78;
  width: 100%;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>