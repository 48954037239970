<template>
  <div id="app">
    <div id="nav">
      <div class="Logo">
        <img alt="Vue logo" src="./assets/logo.svg" />
      </div>
      <div>
        <router-link class="navList" to="/">首页</router-link>
        <!--<router-link class="navList" to="/Technology">植发技术</router-link>-->
        <router-link class="navList" to="/Honor">公司荣誉</router-link>
        <router-link class="navList" to="/Expert">植发专家</router-link>
        <router-link class="navList" to="/Department">全国院部</router-link>
        <router-link class="navList" to="/Bigevent">碧莲盛大事件</router-link>
      </div>
    </div>

    <router-view />
	<v-footer></v-footer>
  </div>
</template>

<script>
	import footer from './components/footer'
export default {
  data() {
    return {};
  },
  components: {
	'v-footer':footer
  }
};
</script>
<style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 1920px;
}

#nav {
  width: 1920px;
  height: 80px;
  /* background: rgb(52, 124, 249); */
  background: transparent;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  z-index: 3001;
}
#nav a {
  font-weight: bold;
  color: #ffffff;
  opacity: 0.6;
}

#nav a.router-link-exact-active {
  color: #ffffff;
  opacity: 1;
}
.Logo {
  margin-left: 60px;
  line-height: 118px;
  width: 80px;
}
.navList {
  line-height: 80px;
  margin-right: 40px;
  width: 28px;
  height: 20px;
  font-family: PingFangSC-Medium;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: justify;
  font-weight: 500;
  text-decoration: none;
}

.textStyle {
  margin-left: 10px;
  width: 100%;
  height: 17px;
  font-family: PingFangSC-Medium;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  font-weight: 500;
  line-height: 20px;
}
</style>
